
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";
import { ElMessage } from "element-plus";
import { VueEditor } from "vue3-editor";
import JwtService from "@/core/services/JwtService";
import Quill from "quill";
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl);

interface Document {
  title: string;
  subject: string[];
  level: number[];
  description: string;
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface Level {
  value: number;
  label: string;
  schoolTypes: string[];
}

export default defineComponent({
  name: "add-my-document",
  components: {
    Field,
    VueEditor,
    ErrorMessage,
    Form,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const uploadPercentage = ref(0);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const cancelButtonRef = ref<null | HTMLButtonElement>(null);
    const upload = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);
    const uploadCover = ref<any>(null);
    const uploadFiles = ref<any>([]);
    const coverPicture = ref<File | null>(null);
    const document = ref<Document>({
      title: "",
      subject: [],
      level: [],
      description: "",
    });

    const allLevels = ref<Level[]>([
      {
        value: -2,
        label: t("document.levels.niveauPre"),
        schoolTypes: ["jardinEnfant", "ecole", "primaireEtCollege"],
      },
      {
        value: -1,
        label: t("document.levels.niveau0"),
        schoolTypes: ["jardinEnfant", "ecole", "primaireEtCollege"],
      },
      {
        value: 1,
        label: t("document.levels.niveau1"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 2,
        label: t("document.levels.niveau2"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 3,
        label: t("document.levels.niveau3"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 4,
        label: t("document.levels.niveau4"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 5,
        label: t("document.levels.niveau5"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 6,
        label: t("document.levels.niveau6"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 7,
        label: t("document.levels.niveau7"),
        schoolTypes: ["college", "collegeEtLycee", "primaireEtCollege"],
      },
      {
        value: 8,
        label: t("document.levels.niveau8"),
        schoolTypes: ["college", "collegeEtLycee", "primaireEtCollege"],
      },
      {
        value: 9,
        label: t("document.levels.niveau9"),
        schoolTypes: ["college", "collegeEtLycee", "primaireEtCollege"],
      },
      {
        value: 10,
        label: t("document.levels.niveau10"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
      {
        value: 11,
        label: t("document.levels.niveau11"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
      {
        value: 12,
        label: t("document.levels.niveau12"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
      {
        value: 13,
        label: t("document.levels.niveau13"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
    ]);

    const levels = computed(() =>
      allLevels.value.filter((level) =>
        level.schoolTypes.includes(store.getters.serverConfigUrl.building.type)
      )
    );

    const editorOptions = {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["link"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
        ],
        magicUrl: true,
      },
    };

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const fetchSubjects = async () => {
      ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
          trimester: trimester,
        },
      })
        .then(({ data }) => {
          let subjectList: Subject[] = [];
          for (const classroom of data) {
            subjectList.push(...classroom.subjects);
          }

          //remove duplicated
          subjectList = subjectList.filter(
            (subject, index) =>
              subjectList.findIndex((s) => s._id == subject._id) === index &&
              !modules.value.find((m) =>
                m.subjects.find((ss) => ss._id == subject._id)
              ) &&
              subject.status !== "inactive"
          );

          subjects.value = subjectList;
        })
        .catch((e) => console.log(e));
    };

    fetchSubjects();

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.array()
        .min(1, t("course.requiredSubject"))
        .required(t("course.requiredSubject")),
      level: Yup.array()
        .min(1, t("course.requiredLevels"))
        .required(t("course.requiredLevels")),
      resources: Yup.mixed().test(
        "required-resources",
        t("document.requiredResources"),
        () => uploadFiles.value.length > 0
      ),
    });

    const mockHttpRequest = (options) => {
      return true;
    };

    const validateCoverPicture = (file) => {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (!allowedTypes.includes(file.type)) {
        ElMessage.error(t("document.invalidFileType"));
        return false;
      }

      coverPicture.value = file;
      return true;
    };

    const removeCoverPicture = () => {
      if (uploadCover.value && uploadCover.value.clearFiles) {
        uploadCover.value.clearFiles();
      }
      coverPicture.value = null;
    };

    const handleExceedCover = (files) => {
      if (uploadCover.value && uploadCover.value.clearFiles) {
        uploadCover.value.clearFiles();
      }

      const newFile = files[0];
      if (validateCoverPicture(newFile)) {
        coverPicture.value = newFile;
        uploadCover.value?.handleStart(newFile);
      }
    };

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };
    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    const submit = () => {
      const data = new FormData();

      data.append("title", document.value.title);
      document.value.subject.forEach((subject) => {
        data.append("subject[]", subject);
      });
      document.value.level.forEach((level) => {
        data.append("level[]", level.toString());
      });
      if (
        document.value.description &&
        document.value.description.trim() !== ""
      ) {
        data.append("description", document.value.description);
      }
      if (coverPicture.value) {
        data.append("coverPicture", coverPicture.value);
      }
      uploadFiles.value.forEach((file) => {
        data.append("files", file.raw);
      });

      if (!submitButtonRef.value) {
        return;
      }
      if (!cancelButtonRef.value) {
        return;
      }
      submitButtonRef.value.disabled = true;
      cancelButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      ApiService.put(
        "/library/item",
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(({ data }) => {
          event("Add document", {
            event_category: "document",
            event_label: "document section",
            value: 1,
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("document.addedDocument"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/document/${data._id}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("document.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("document.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
        })
        .finally(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          if (cancelButtonRef.value) {
            cancelButtonRef.value.disabled = false;
          }
        });
    };
    return {
      handleExceedCover,
      t,
      levels,
      subjects,
      modules,
      upload,
      document,
      validationSchema,
      submit,
      submitButtonRef,
      cancelButtonRef,
      updateUploadFiles,
      handleExceed,
      customColorMethod,
      uploadPercentage,
      validateCoverPicture,
      removeCoverPicture,
      coverPicture,
      mockHttpRequest,
      uploadFiles,
      editorOptions,
    };
  },
});
